/* You can add global styles to this file, and also import other style files */
:root {
  --marbles-font: "72", "72full", Arial, Helvetica, sans-serif;
  --marbles-font-bold: "72-Bold", "72-Boldfull", "72", "72full", Arial, Helvetica, sans-serif;
  --marbles-color-darkblue: #1d2d3e;
  --marbles-color-text-body: #1d2d3e;
  --marbles-color-3: #556b82;
  --marbles-icon-group-gap: 0.5rem;
}
body {
  margin: 0;
  font-family: var(--marbles-font);

  @media (min-width: 46.25rem) {
    position: static;
    padding-bottom: 0;
  }
}

.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.fd-menu .fd-menu__title {
  min-width: 52px;
}

.fd-container.fd-form-layout-grid-container .fd-form-item + .fd-form-item {
  margin-top: 0.5rem;
}
